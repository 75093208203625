import React from 'react';
import ProviderPage from '../../components/ProviderPage';

const providerImage = require('../../images/campusbord.jpg');

const providerID = '-KUSRnYxCZKiqENF8OFT';
const providerName = 'Campus UT Huisartsenpraktijk';
const providerPhoneNumber = '053 - 2030204';
const providerWebsite = 'https://campushuisarts.nl/';
const providerRemarks =
  'Je kunt alleen online een afspraak maken als je al staat ingeschreven bij de praktijk.';

const providerLocations = {
  ut: {
    name: 'Campus UT Huisartsenpraktijk',
    address: 'De sleutel (gebouw 58 van ACASA)\nCampuslaan 99\n7522NE Enschede',
    coordinates: {
      lat: 52.246128,
      lng: 6.8532
    }
  },
  saxion: {
    name: 'Campus Saxion Huisartsenpraktijk',
    address: 'M.H. Tromplaan 28\n7513AB Enschede',
    coordinates: {
      lat: 52.220729,
      lng: 6.885688
    }
  }
};

const providerDescription = `De Campus UT Huisartsenpraktijk is de studentenhuisartsenpraktijk in Enschede. De campushuisarts in Enschede heeft vestigingen op de campus van de UT (Universiteit Twente) en op de campus van de Saxion hogeschool. De studentenarts is er speciaal voor alle studenten en medewerkers van de Universiteit Twente en de Saxion Hogeschool Enschede. Maar ook andere patiënten zijn natuurlijk van harte welkom.

Je kunt nu heel eenvoudig een afspraak met de campushuisarts in Enschede maken via Planl. Klik simpelweg op de bovenstaande knop. Via Planl kan je zowel een afspraak maken op de Campus van de Universiteit Twente als op de Campus van de Saxion Hogeschool.`;

const providerMetaDescription = 'Afspraak maken bij de campus UT huisartsenpraktijk in Enschede';
const providerMetaKeywords =
  'Afspraak maken,Campus UT huisartsenpraktijk,Campushuisarts,Enschede,Saxion,Huisarts UT,Huisarts Saxion';

export default function StudentenArtsUtrecht() {
  return (
    <ProviderPage
      providerID={providerID}
      providerName={providerName}
      providerPhoneNumber={providerPhoneNumber}
      providerWebsite={providerWebsite}
      providerImage={providerImage}
      providerRemarks={providerRemarks}
      providerLocations={providerLocations}
      providerDescription={providerDescription}
      providerMetaDescription={providerMetaDescription}
      providerMetaKeywords={providerMetaKeywords}
    />
  );
}
